code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

thead.ant-table-thead {
  color: #262626 !important;
  font-weight: 500 !important;
}

tbody.ant-table-tbody {
  color: #595959 !important;
  font-weight: 300 !important;
}

th.ant-table-cell {
  background-color: #e1ecf5 !important;
}
