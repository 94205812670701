.ant-switch-checked.traverse-switch {
  background-color: #3b7aad;
}

.traverse-form-item-table .ant-form-item-control-input {
  /*this is because we use an top left border only approach to make the tables look like cells in a spreadsheet.*/
  min-height: 31px;
}

/*copy paste these 3 blocks for each element in a table that you need to override (like toggle, dropdown, input etc.)-*/
.traverse-form-item-table tr > td.ant-table-cell input.ant-input {
  border-top: 1px solid #e4e4e4 !important;
  border-right: 0 !important;
  border-bottom: 0 !important;
  border-left: 1px solid #e4e4e4 !important;
  transition: none !important;
  height: 31px !important;
}

.traverse-form-item-table tr > td:last-child.ant-table-cell input.ant-input {
  border-top: 1px solid #e4e4e4 !important;
  border-right: 1px solid #e4e4e4 !important;
  border-bottom: 0 !important;
  border-left: 1px solid #e4e4e4 !important;
  height: 31px !important;
}

.traverse-form-item-table tr:last-child > td.ant-table-cell input.ant-input {
  border-top: 1px solid #e4e4e4 !important;
  border-bottom: 1px solid #e4e4e4 !important;
  border-left: 1px solid #e4e4e4 !important;
  height: 31px !important;
}

.traverse-form-item-table tr > td.ant-table-cell input.ant-input:focus {
  border: 2px solid #3b7aad !important;
  box-shadow: none !important;
}

/*--------------------------------------------------------------------------------------------------------------------*/

/*copy paste these 3 blocks for each element in a table that you need to override (like toggle, dropdown, input etc.)-*/
.traverse-form-item-table tr > td.ant-table-cell .ant-select-selector {
  border-top: 1px solid #e4e4e4 !important;
  border-right: 0 !important;
  border-bottom: 0 !important;
  border-left: 1px solid #e4e4e4 !important;
  height: 31px !important;
  transition: none !important;
}

.traverse-form-item-table
  tr
  > td:last-child.ant-table-cell
  .ant-select-selector {
  border-top: 1px solid #e4e4e4 !important;
  border-right: 1px solid #e4e4e4 !important;
  border-bottom: 0 !important;
  border-left: 1px solid #e4e4e4 !important;
  height: 31px !important;
}

.traverse-form-item-table
  tr:last-child
  > td.ant-table-cell
  .ant-select-selector {
  border-top: 1px solid #e4e4e4 !important;
  border-bottom: 1px solid #e4e4e4 !important;
  border-left: 1px solid #e4e4e4 !important;
  height: 31px !important;
}

.traverse-form-item-table
  tr
  > td.ant-table-cell
  .ant-select-open
  .ant-select-selector {
  border: 2px solid #3b7aad !important;
  box-shadow: none !important;
  transition: none !important;
}

.traverse-form-item-table
  tr
  > td.ant-table-cell
  .ant-select-focused
  .ant-select-selector {
  border: 2px solid #3b7aad !important;
  box-shadow: none !important;
  transition: none !important;
}

/*--------------------------------------------------------------------------------------------------------------------*/

/*copy paste these 3 blocks for each element in a table that you need to override (like toggle, dropdown, input etc.)-*/
.traverse-form-item-table tr > td.ant-table-cell .ant-picker {
  border-top: 1px solid #e4e4e4 !important;
  border-right: 0 !important;
  border-bottom: 0 !important;
  border-left: 1px solid #e4e4e4 !important;
  height: 31px !important;
  transition: none;
  border-radius: 0 !important;
}

.traverse-form-item-table tr > td:last-child.ant-table-cell .ant-picker {
  border-top: 1px solid #e4e4e4 !important;
  border-right: 1px solid #e4e4e4 !important;
  border-bottom: 0 !important;
  border-left: 1px solid #e4e4e4 !important;
  height: 31px !important;
}

.traverse-form-item-table tr:last-child > td.ant-table-cell .ant-picker {
  border-top: 1px solid #e4e4e4 !important;
  border-bottom: 1px solid #e4e4e4 !important;
  border-left: 1px solid #e4e4e4 !important;
  height: 31px !important;
}

.traverse-form-item-table
  tr
  > td.ant-table-cell
  .ant-picker.ant-picker-focused {
  border: 2px solid #3b7aad !important;
  box-shadow: none !important;
}
/*--------------------------------------------------------------------------------------------------------------------*/

.traverse-form-item-table tr td.ant-table-cell input {
  border-radius: 0 !important;
}

.traverse-form-item-table tr .ant-select-selector {
  border-radius: 0 !important;
}

.traverse-form-item-table td.ant-table-cell {
  margin-bottom: 0 !important;
}

.traverse-form-item-table td.ant-table-cell > .ant-row.ant-form-item {
  margin-bottom: 0 !important;
}

.traverse-form-item-table td.ant-table-cell {
  padding: 0 !important;
  border-right: 0 !important;
  border-bottom: 0 !important;
}

.traverse-form-item-table .ant-table-thead > tr > th {
  padding: 0 !important;
  font-size: 11px;
  text-align: center;
  background: #fff;
  color: #001529 !important;
  border-top: 1px solid #e4e4e4 !important;
  border-left: 1px solid #e4e4e4 !important;
  border-right: none !important;
  border-bottom: none !important;
  border-radius: 0 !important;
  box-shadow: none;
  font-weight: 700;
}

.traverse-form-item-table .ant-table-thead > tr > th:first-child {
  border-radius: 2px 0 0 0 !important;
}

.traverse-form-item-table .ant-table-thead > tr > th:last-child {
  border-right: 1px solid #e4e4e4 !important;
  border-radius: 0 2px 0 0 !important;
}

.traverse-form-item-table
  .ant-space.ant-space-horizontal.ant-space-align-center {
  width: 100%;
}

.traverse-form-item-table .ant-table-row .ant-space-item {
  margin-right: 0 !important;
  width: 100%;
}

.traverse-form-item .ant-form-item-label {
  white-space: normal;
}

.traverse-form-item .ant-input-disabled {
  color: #000;
}

.traverse-slider-group {
  display: flex;
}

.trv-table-title-override {
  box-sizing: border-box;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  padding: 0 0 0 8px;
  height: 48px;
  background: #e1e5e8;
  border: 2px solid #e1e5e8;
  border-radius: 2px;
  text-transform: capitalize;
  font-weight: 500;
}
