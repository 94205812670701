.mdf-controls {
  padding: 1rem;
}

.mdf-controls--end {
  border-right: 1px solid #000c;
}

.mdf__bar {
  display: flex;
  align-items: flex-end;
}

.mdf__bar * {
  margin: 0.25rem 0.5rem;
}

#mdf__main h1 {
  margin-top: 1rem;
}
