.trv-save-button-override.ant-btn-primary > svg {
  margin-right: 8px;
}

.trv-save-button-override.ant-btn-primary > span {
  font-family: "Blender Pro";
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
}

.trv-save-button-override.ant-btn-primary {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 2px 4px rgba(16, 22, 26, 0.2),
    0 8px 24px rgba(16, 22, 26, 0.2);
  color: #fff;
  background: #219653;
  border-color: #219653;
  text-shadow: none;
}

.trv-save-button-override.ant-btn-primary:hover {
  background-color: #27ae60;
  border-color: #27ae60;
}
